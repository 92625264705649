import { BrowserRouter, Routes,  Route} from "react-router-dom";

import { Home } from "./pages/Home";
import { EditAccount } from "./pages/EditAccount";
import { IssueReceipt } from "./pages/IssueReceipt";
import { RegisterClient } from "./pages/RegisterClient";
import { AuthContextProvider } from './contexts/AuthContext'

function App() {
  return (
    <BrowserRouter>
      <AuthContextProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/account/edit" element={<EditAccount />}/>
          <Route path="/issue-receipt" element={<IssueReceipt />}/>
          <Route path="/register-client" element={<RegisterClient />}/>
        </Routes>
      </AuthContextProvider>
    </BrowserRouter>
  );
}

export default App;
