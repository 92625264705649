import { Container, Navbar } from "react-bootstrap";

import logoLightImg from "../../assets/images/logo-light.png"

import { DropdownProfile } from "../DropdownProfile";

import "./styles.scss";
import { UpgradeButton } from "../UpgradeButton";


export function NavBar() {
    return (
        <Navbar className="bg-dark border-bottom border-body" data-bs-theme="dark">
            <Container>
                <Navbar.Brand href="/issue-receipt">
                    <img
                    alt="RecibosNow"
                    src={logoLightImg}
                    width="100"
                    height="30"
                    className="d-inline-block align-top"
                    />{' '}
                </Navbar.Brand>
                <ul className="navbar-list">
                        <div className="upgrade-plan">
                            <UpgradeButton></UpgradeButton>
                        </div>
                        <div className="box-change-theme">
                            <input type="checkbox" className="checkbox" id="cbChangeTheme"/>
                    
                            <label className="labelChangeTheme" htmlFor="cbChangeTheme">
                                <i className="bi bi-moon"></i>
                                <i className="bi bi-brightness-high-fill"></i>
                                <div className="ball"></div>
                            </label>
                        </div>
                        <div className="profile-photo">
                            <DropdownProfile></DropdownProfile>
                        </div>
                </ul>     
            </Container>
            
        </Navbar>
    );
}