import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { FloatingLabel, Form, InputGroup } from "react-bootstrap";
import InputMask from 'react-input-mask';
import { jsPDF } from 'jspdf';


import { Button } from "../components/Button";
import { NavBar } from "../components/NavBar";
import { ClientCard } from "../components/ClientCard";

import { numberInWords } from '../utils/numberInWords'

import { database, updateUserSubscriptionStatus } from "../services/firebase";

import '../styles/issue-receipt.scss';

import { useAuth } from "../hooks/useAuth";
import { useCards } from "../hooks/useCards";
import toast, { Toaster } from "react-hot-toast";
import { handleCPF } from "../utils/utils";
import { useSubscription } from "../hooks/useSubscription";
 
export function IssueReceipt() {
    const { user } = useAuth();
    const uid = user?.id;
    const navigate = useNavigate();

    const [ clientID, setClientID ] = useState('');

    const [ userName, setUserName ] = useState('');
    const [ userCPF, setUserCPF ] = useState('');
    const [ userProfession, setUserProfession ] = useState('');
    const [ userLetterheadFile, setUserLetterheadFile ] = useState('');
    const [ userExtraInfo, setUserExtraInfo ] = useState('');
    
    const [nameInput, setNameInput] = useState('');
    const [CPFInput, setCPFInput] = useState('');
    const [dateInput, setDateInput] = useState('');
    const [descriptionInput, setDescriptionInput] = useState('');
    const [valueInput, setValueInput] = useState("");


    const { cardData, handleDeleteClient } = useCards(uid);
    const { canGenerateReceipt, receiptsCount } = useSubscription(user?.id || " ");
  
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const status = params.get('status');
        const sessionId = params.get('session_id');
        const toastShown = sessionStorage.getItem('toastShown');
        const baseUrl = process.env.REACT_APP_API_URL || '';
        const verifyPayment = async () => {
            if (status === 'success' && sessionId && !toastShown) {
                try {
                    const response = await fetch(`${baseUrl}/api/verify-payment`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ sessionId })
                    });
    
                    const { paymentStatus } = await response.json();
    
                    if (paymentStatus === 'paid' && user) {
                        await updateUserSubscriptionStatus(user.id, 'premium');
                        toast.success('Assinatura realizada com sucesso!');
                    }
                } catch (error) {
                    toast.error('Erro ao verificar pagamento');
                }
                sessionStorage.setItem('toastShown', 'true');
            } else if (status === 'canceled' && !toastShown) {
                toast.error('Assinatura cancelada');
                sessionStorage.setItem('toastShown', 'true');
            }
        };
    
        verifyPayment();
    
        return () => {
            sessionStorage.removeItem('toastShown');
        };
    }, [user]);
   
    const goToRegisterClient = () => {
        navigate('/register-client');
    }

    const handleUseClient = (clientId: string) => {
		// Verifica se um cliente foi selecionado
		if (!clientId) {
		  alert("Selecione um cliente antes de clicar em 'Usar'.");
		  return;
		}

        setClientID(clientId);
	
		// Encontra o cliente selecionado nos dados do cartão
		const selectedClient = cardData.find((client) => client.id === clientId);
	
		// Preenche os inputs com as informações do cliente selecionado
		setNameInput(selectedClient?.responsibleName || "");
		setCPFInput(selectedClient?.responsibleCPF || "");
		setDescriptionInput(selectedClient?.description || "");
		setValueInput(selectedClient?.value || "");

        if (user) {
            database.ref('users/' + user.id).once('value')
            .then(snapshot => {
                const userData = snapshot.val();
                if (userData) {
                    setUserLetterheadFile(userData.letterhead || '');
                }
            })
            .catch(error => {
                console.error('Error fetching user data:', error);
            });
        }
	};

    const dataObj = {
        name: nameInput,
        cpf: CPFInput,
        date: dateInput,
        description: descriptionInput,
        value: valueInput,
        letterhead: userLetterheadFile,
    };

    const handleIssueReceipt = async () => {
        
        if (Object.values(dataObj).some(value => value === "")) {
            toast.error("Preencha todos os campos obrigatórios.");
        }

        if (!canGenerateReceipt()) {
            toast.error('Você atingiu o limite de recibos do plano gratuito. Faça upgrade para continuar.');
            return;
        }
    
        toast.promise(
            (async () => {
                generatePortraitPDF();
                
                if (user) {
                    await database.ref(`users/${user.id}/subscription/receiptsGenerated`).set(receiptsCount + 1);
                }
            })(),
            {
                loading: "Gerando recibo...",
                success: "Recibo gerado com sucesso.",
                error: "Erro ao gerar o recibo.",
            }
        );
    };

    const handleCompleteDescription = (date: string) => {
        setDateInput(date);
        const selectedClient = cardData.find((client) => client.id === clientID);
        
        const idxAddCPF = selectedClient?.description.indexOf("realizad");
        const hasFinancialResponsible = selectedClient?.cpf !== selectedClient?.responsibleCPF;
        const addCPFIfHasFinancialResponsible = selectedClient?.description.slice(0, idxAddCPF) + `(${selectedClient?.cpf}) ` + selectedClient?.description.slice(idxAddCPF);
        const removeNameIfDoesNotHaveFinancialResponsible = selectedClient?.description.replace(` de ${selectedClient?.name}`, "");

        setDescriptionInput(`${hasFinancialResponsible ? addCPFIfHasFinancialResponsible : removeNameIfDoesNotHaveFinancialResponsible} ${date.split("-")[2]}/${date.split("-")[1]}/${date.split("-")[0]}.`);
    };
    
    const fetchUserData = () => {
        if (user) {
        database.ref('users/' + user.id).once('value')
            .then(snapshot => {
            const userData = snapshot.val();
            if (userData) {
                console.log(userData);
                setUserName(userData.name);
                setUserCPF(userData.cpf);
                setUserProfession(userData.expertise)
                setUserLetterheadFile(userData.letterhead)
                setUserExtraInfo(userData.additional)
            }
            })
            .catch(error => {
            console.error('Error fetching user data:', error);
            });
        }
    };
    
    function generatePortraitPDF() {
        const doc = new jsPDF({
            orientation: 'p',
            unit: 'mm',
            format: 'a4',
        });
    
        const base64String = userLetterheadFile;
        let imageType = '';
    
        // Match for image type
        const match = base64String.match(/^data:image\/([a-zA-Z]+);base64,/);
        if (match && match[1]) {
            imageType = match[1];
        } else {
            console.warn('Formato de imagem inválido ou não especificado. Usando "jpeg" como padrão.');
            imageType = 'jpeg'; // Use 'jpeg' or 'png' as a fallback
        }
    
        // Add the image
        try {
            doc.addImage(userLetterheadFile, imageType, 0, -5, 210, 300);
        } catch (error) {
            console.error('Erro ao adicionar a imagem ao PDF:', error);
            return;
        }

        function addReceiptHeader() {
            doc.setFont("Helvetica", "bold");
            doc.setFontSize(15);
            doc.text("RECIBO", 161.5, 81, );
        }
        
        function addServiceValue() {
            doc.setFillColor(0, 0, 255);
            doc.rect(154, 86, 35, 7, 'F');
            doc.setFillColor(0, 0, 0);
        
            doc.setTextColor(255);
            doc.setFontSize(12);
            doc.setFont("Helvetica", "bold");
            doc.text(`R$ ${valueInput},00`, 162, 90.5);
            doc.setTextColor(0);
        }
        
        function addUserDetails() {
            doc.setFontSize(11);
            doc.setFont("Helvetica", "normal");
            doc.text(`Nome: ${userName}`, 30, 80, );
            doc.text(`CPF: ${userCPF}`, 30, 85);
            doc.text(`Profissão: ${userProfession}`, 30, 90);
            doc.text(`${userExtraInfo}`, 30, 95);
        
            doc.setFillColor(0, 0, 0);
            doc.rect(25, 77.3, 0.6, 18, 'F');
            doc.setFillColor(0, 0, 0);
        }
        
        function addClientDetails() {
            doc.setFontSize(14);
            doc.setFont("Helvetica", "normal");
            doc.text(`Recebemos de: ${nameInput}`, 25, 151, );
            doc.text(`CPF/CNPJ: ${CPFInput}`, 25, 158, );
            var valueInWords = numberInWords(parseInt(valueInput))[0].toUpperCase() + numberInWords(parseInt(valueInput)).slice(1, numberInWords(parseInt(valueInput)).length);

            doc.text(`A importância de: R${valueInput},00 (${valueInWords} reais)`, 25, 165, );
        }

        let line = 172;
        
        function addServiceDescription() {    
            const lineHeight = 7;
            const leftMargin = 25;
            const wrapWidth = 135;
            const longString = descriptionInput;
            console.log(longString);
            
            const splitText = doc.splitTextToSize(longString, wrapWidth);
        
            for (var i = 0, length = splitText.length; i < length; i++) {
                if (i === 0) {
                    doc.text("Referente " + splitText[i], leftMargin, line)
                } else {
                    doc.text(splitText[i], leftMargin, line)
                }
                line += lineHeight;
            }
        }

        var year = dateInput.slice(0,4);
        var month = dateInput.slice(5,7);
        var day = dateInput.slice(8,10);
        
        function addReceiptDate() {
            doc.text(`Data do Recibo: ${day}/${month}/${year}`, 25, line);
        }

        function generatePDF() {
            addReceiptHeader();
            addServiceValue();
            addUserDetails();
            addClientDetails();
            addServiceDescription();
            addReceiptDate();
        
            doc.save(`${nameInput.replaceAll(" ", "_")}_${day}_${month}_${year}.pdf`);
        }

        generatePDF();
    }

    const clearFields = () => {
		setNameInput("");
		setCPFInput("");
        setDateInput("");
        setDescriptionInput("");
		setValueInput("");
        toast.success("Campos limpos com sucesso");
	};

    useEffect(() => {
        fetchUserData(); // Chama a função ao carregar a página
    })

    return (
        <div className="outerDiv">
            <header>
                <NavBar></NavBar>
            </header>
            <main>
                <div className="container-fluid text-center">
                    <Toaster
						position="top-center"
						reverseOrder={true}
					/>
                    <div className="row">
                        <div className="col-sm-9 col-md-8 col-lg-6 col-xl-4 col-xxl-3 col-11 m-xl-3 m-4" id="client-data">
                            <div className="client-data-subtitle">
                                <h2><span>Dados </span>do cliente</h2>
                            </div>

                            <FloatingLabel controlId="floatingInput" label="Nome completo">
                                <Form.Control 
                                    type="text" 
                                    placeholder="Joe Doe"
                                    value={nameInput}
                                    onChange={event => setNameInput(event.target.value)}     
                                />
                            </FloatingLabel>

                            <FloatingLabel controlId="floatingInput" label="CPF">
                                <InputMask
                                    className="form-control"
                                    type="text"
                                    mask="999.999.999-99"
                                    value={CPFInput} 
                                    onChange={event => setCPFInput(event.target.value)}
                                    onBlur={event => handleCPF(event.target.value, toast)}>
                                </InputMask>
                            </FloatingLabel>

                            <FloatingLabel controlId="floatingInput" label="Data" >
                                <Form.Control 
                                    type="date" 
                                    placeholder="00/00/0000" 
                                    value={dateInput}
                                    onChange={event => handleCompleteDescription(event.target.value)}
                                />
                            </FloatingLabel>

                            <FloatingLabel controlId="floatingInput" label="Descrição do serviço">
                                <Form.Control 
                                    type="text"
                                    placeholder=""
                                    value={descriptionInput}
                                    onChange={event => setDescriptionInput(event.target.value)}
                                />
                            </FloatingLabel>

                            <InputGroup>
                                <InputGroup.Text>R$</InputGroup.Text>
                                <FloatingLabel controlId="floatingInput" label="Valor do serviço">
                                    <Form.Control 
                                        type="number" 
                                        placeholder="250.00" 
                                        value={valueInput}
                                        onChange={event => setValueInput(event.target.value)}
                                    />
                                </FloatingLabel>
                            </InputGroup>

                            <Button style={{ marginTop: "1rem" }} onClick={handleIssueReceipt} id="actionButton">Emitir recibo</Button>
                            <Button isSecondary onClick={clearFields} id="actionButton">Limpar campos</Button>
                            <Button isSecondary onClick={goToRegisterClient} id="actionButton">Ir para Cadastrar cliente</Button>
                        </div>

                        <div className="col-md-12 col-lg-12 col-xl-7 col-xxl-7 col-12 m-xl-3 mt-4 client-cards">
                            <div className="mb-4">
                                <h2>Cards dos clientes</h2>
                            </div>
                            
                            <div className="cards">
                            {cardData.map((client) => (
                                <ClientCard 
                                    key={client.id} 
									client={client}
                                    valueFirstButton="Usar"
                                    onUse={() => handleUseClient(client.id)}
									onDelete={() => handleDeleteClient(client.id)}
                                />
                            ))}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}
