const unidades: string[] = ['', 'um', 'dois', 'três', 'quatro', 'cinco', 'seis', 'sete', 'oito', 'nove'];
const dez_a_dezenove: string[] = ['dez', 'onze', 'doze', 'treze', 'quatorze', 'quinze', 'dezesseis', 'dezessete', 'dezoito', 'dezenove'];
const dezenas: string[] = ['', 'dez', 'vinte', 'trinta', 'quarenta', 'cinquenta', 'sessenta', 'setenta', 'oitenta', 'noventa'];
const centenas: string[] = ['', 'cento', 'duzentos', 'trezentos', 'quatrocentos', 'quinhentos', 'seiscentos', 'setecentos', 'oitocentos', 'novecentos'];

export function numberInWords(numero: number): string {
    if (numero === 0) {
        return 'zero';
    }

    if (numero < 0 || numero > 999999) {
        return 'Número fora do intervalo suportado';
    }

    let extenso: string = '';

    if (numero >= 1000) {
        extenso += numberInWords(Math.floor(numero / 1000)) + ' mil';
        numero %= 1000;
        if (numero !== 0) {
            extenso += ' ';
        }
    }

    if (numero >= 100) {
        if (numero === 100) {
            extenso += 'cem';
            numero = 0;
        } else {
            extenso += centenas[Math.floor(numero / 100)];
            numero %= 100;
            if (numero !== 0) {
                extenso += ' e ';
            }
        }
    }

    if (numero >= 20) {
        extenso += dezenas[Math.floor(numero / 10)];
        numero %= 10;
        if (numero !== 0) {
            extenso += ' e ';
        }
    }

    if (numero >= 10) {
        extenso += dez_a_dezenove[numero - 10];
        numero = 0;
    }

    if (numero > 0) {
        extenso += unidades[numero];
    }

    return extenso;
}