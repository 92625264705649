import { FormEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import googleIconImg from '../assets/images/google-icon.svg'
import logoImg from '../assets/images/logo.png'
import logoLightImg from '../assets/images/logo-light.png'

import { database } from '../services/firebase';

import { Button } from '../components/Button'
import { useAuth } from '../hooks/useAuth'

import '../styles/auth.scss'

export function Home() {
    const navigate = useNavigate();
    const { user, signInWithGoogle } = useAuth();
    const [signInCode, setSignInCode] = useState('');

    async function handleSignInWithGoogle() {
        if (!user) {
            await signInWithGoogle()
        }

        navigate('/account/edit')
    }

    async function handleSignInWithCode(event: FormEvent) {
        event.preventDefault();

        if (signInCode.trim() === '') {
            return;
        }

        const codeRef = await database.ref(`codes/${signInCode}`).get();

        if (!codeRef.exists()) {
            alert('Code does not exist.');
            return;
        }

        if (codeRef.val().unauthorizedAt) {
            alert('Unauthorized code.');
            return;
        }

        navigate('/home');
    }

    return (
        <div id="page-auth">
            <aside>
                <img src={logoImg} alt="RecibosNow" />
                <strong>Gere recibos de forma fácil, rápida e simples</strong>
            </aside>
            <main>
                <div className="main-content">
                    <img src={logoLightImg} alt="RecibosNow" />
                    <button onClick={handleSignInWithGoogle} className="signin-google">
                        <img src={googleIconImg} alt="Logo do Google" />
                        Entrar com o Google
                    </button>
                    <div className="separator">ou entre direto</div>
                    <form onSubmit={handleSignInWithCode}>
                        <input 
                            type="text" 
                            placeholder="Digite o seu código premium"
                            onChange={event => setSignInCode(event.target.value)}
                            value={signInCode}
                        />
                        <Button type="submit">
                            Entrar
                        </Button>
                    </form>
                </div>
            </main>
        </div>
    )
}