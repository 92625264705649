import firebase from 'firebase/compat/app'
import "firebase/compat/auth"
import "firebase/compat/database"
import "firebase/compat/storage";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
};


firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const database = firebase.database();
const storage = firebase.storage();


const updateUserSubscriptionStatus = async (userId: string, status: string) => {
    return database.ref(`users/${userId}/subscription`).set({
        status,
        updatedAt: new Date().toISOString(),
        receiptsGenerated: 0
    });
};

export { firebase, auth, database, storage, updateUserSubscriptionStatus };



