import { FormEvent, useCallback, useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { FloatingLabel, Form } from 'react-bootstrap';

import { Button } from '../components/Button';
import { NavBar } from '../components/NavBar';

import '../styles/edit-account.scss';
import ConvertFileToBase64 from '../utils/convertFileToBase64';

import { firebase, database, storage } from '../services/firebase';

import { useAuth } from '../hooks/useAuth';
import { handleCPF } from '../utils/utils';
import toast, { Toaster } from 'react-hot-toast';

export function EditAccount() {
    const { user } = useAuth();
    
    const [CPFInput, setCPFInput] = useState('');
    const [nameInput, setNameInput] = useState('');
    const [expertiseInput, setExpertiseInput] = useState('');
    const [additionalInfoValue, setAdditionalInfoValue] = useState('');
    const [letterheadFile, setLetterheadFile] = useState<File | null>(null);
    const [letterheadBase64, setLetterheadBase64] = useState('');

    const userName = user?.name.replaceAll('.', '').replaceAll('_', '').replaceAll(' ', '').toLowerCase();

    const handleInfoUser = async (event: FormEvent) => {
        event.preventDefault(); 

        if (user && letterheadFile) {
            const storageRef = storage.ref();
            const userDirectoryRef = storageRef.child(`letterheads/${user.id}/${letterheadFile.name}`).put(letterheadFile);

            userDirectoryRef.on('statechange',
            (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
                console.log('Upload is paused');
                break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
                console.log('Upload is running');
                break;
            }
            },
            (error) => {
                alert("Failed to upload the file: " + error)
            },

            async () => {
                try {      
                    await database.ref(`users/${user.id}`).set({
                        name: nameInput,
                        cpf: CPFInput,
                        expertise: expertiseInput,
                        additional: additionalInfoValue,
                        letterhead: letterheadBase64,
                        username: userName,
                        email: user.email,
                        avatar: user.avatar,
                    });
                    toast.success("Informações atualizadas com sucesso")
                    console.log('Informações do usuário atualizadas com sucesso!');
                } catch (error) {
                    toast.error("Houve um problema ao atualizar suas informações")
                    console.error('Erro ao atualizar informações do usuário: ', error);
                }
            })
            } else {
                console.log("Error saving data to database");  
            }
    };
      
    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const convertFileToBase64 = new ConvertFileToBase64()
        await convertFileToBase64.handleFileInputChange(event);

        setLetterheadFile(convertFileToBase64.state.file);
        setLetterheadBase64(convertFileToBase64.state.base64URL);
    };

    // Função para obter dados do usuário e preencher os campos
    const fetchUserData = useCallback(() => {
        if (user) {
            database.ref('users/' + user.id).once('value')
                .then(snapshot => {
                    const userData = snapshot.val();
                    if (userData) {
                        setNameInput(userData.name || '');
                        setCPFInput(userData.cpf || '');
                        setExpertiseInput(userData.expertise || '');
                        setLetterheadFile(userData.letterhead || '');
                        setAdditionalInfoValue(userData.additional || '');
                    }
                })
                .catch(error => {
                    console.error('Error fetching user data:', error);
                });
        }
    }, [user]);

    useEffect(() => {
        fetchUserData(); // Chama a função ao carregar a página
    }, [fetchUserData])
        

    return (
        <div className='main'>
            <Toaster
                position="top-center"
                reverseOrder={true}
            />
            <header>
                <NavBar></NavBar>
            </header>
            <div className="row">
                <div className="col-xl-4 col-lg-3 col-md-2 mt-4 left-side">
                    <h1><span>Edita</span>r conta</h1>
                </div>
            
                <div className="col-xl-4 col-lg-6 col-md-8 mt-4 personal-info">
                    <div className="form-group user-info">
                        <img src={user?.avatar} className="avatar img-circle" alt="avatar"/>
                        <h2>{user?.name}</h2>
                    </div>
                    
                    <div className="form-user">
                        <h3>Informações - Recibo</h3>
                        <form className="form-horizontal">
                            <FloatingLabel controlId="floatingInput" label="Nome completo">
                                <Form.Control type="text" placeholder="Joe Doe" value={nameInput} onChange={event => setNameInput(event.target.value)} />
                            </FloatingLabel>

                            <FloatingLabel controlId="floatingInput" label="CPF">
                                <InputMask
                                    className="form-control"
                                    type="text"
                                    mask="999.999.999-99"
                                    value={CPFInput} 
                                    onChange={event => setCPFInput(event.target.value)}
                                    onBlur={event => handleCPF(event.target.value, toast)}
                                >
                                </InputMask>
                            </FloatingLabel>

                            <FloatingLabel controlId="floatingInput" label="Profissão" >
                                <Form.Control type="text" placeholder="Psicólogo Clínico" value={expertiseInput} onChange={event => setExpertiseInput(event.target.value)}/>
                            </FloatingLabel>

                            <FloatingLabel controlId="floatingInput" label="Informação importante">
                                <Form.Control type="text" placeholder="CRP: 00/00000" value={additionalInfoValue} onChange={event => setAdditionalInfoValue(event.target.value)}/>
                            </FloatingLabel>

                            <FloatingLabel controlId="floatingInput" label="Folha Timbrada">
                                <Form.Control type="file" placeholder="" accept="image/png, image/jpg, image/jpeg, application/pdf" onChange={handleFileChange}/>
                            </FloatingLabel>
                            

                            <div className="separator col-lg-12">Informações de login</div>

                            <FloatingLabel className="login-info" controlId="floatingInput" label="Nome de usuário" >
                                <Form.Control type="text" value={userName}  disabled/>
                            </FloatingLabel>

                            <FloatingLabel className="login-info" controlId="floatingInput" label="Email">
                                <Form.Control type="text" value={user?.email} disabled/>
                            </FloatingLabel>

                            <FloatingLabel className="login-info" controlId="floatingInput" label="Código Premium">
                                <Form.Control type="text" value="MS91K1S8" disabled/>
                            </FloatingLabel>

                            <div className="save-cancel-buttons">
                                <Button type="submit" value="Save Changes" onClick={event => handleInfoUser(event)}>Salvar</Button>
                                <Button type="reset" isOutlined isDanger>Cancel</Button>
                            </div>
                        </form>
                    </div>
                </div>

                <div className="col-xl-4 col-lg-3 col-md-2 right-side">
                </div>
            </div>
            <hr/>
        </div>
    );
}