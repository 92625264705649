import { useState, useEffect } from "react";
import { database } from "../services/firebase";
import toast from "react-hot-toast";

type FirebaseClient = Record<
	string,
	{
		responsibleName: string;
		responsibleCPF: string;
		name: string;
		cpf: string;
		email: string;
		description: string;
		phone: string;
		value: string;
	}
>;

type ClientData = {
	id: string;
	responsibleName: string;
	responsibleCPF: string;
	name: string;
	cpf: string;
	email: string;
	description: string;
	phone: string;
	value: string;
};

export function useCards(uid: string | undefined) {
	const [cardData, setCardData] = useState<ClientData[]>([]);

  const handleDeleteClient = (clientId: string) => {
    if (!uid) return;

    const clientsRef = database.ref(`clients/${uid}/${clientId}`);

    clientsRef
      .remove()
      .then(() => {
				toast.success("Cliente removido com sucesso")
        console.log("Cliente removido com sucesso!");
      })
      .catch((error) => {
        console.error("Erro ao remover cliente:", error);
      });
  };

	useEffect(() => {
		if (!uid) return; // Ensure uid is defined before proceeding

		const clientsRef = database.ref(`clients/${uid}`);

		clientsRef.on("value", (client) => {
			const databaseClient: FirebaseClient = client.val();

      if (!databaseClient) {
        // Dados são nulos ou indefinidos
        setCardData([]);
        return;
      }

			const parsedClients = Object.entries(databaseClient).map(
				([key, value]) => {
					return {
						id: key,
						responsibleName: value.responsibleName,
						responsibleCPF: value.responsibleCPF,
						name: value.name,
						cpf: value.cpf,
						email: value.email,
						description: value.description,
						phone: value.phone,
						value: value.value,
					};
				}
			);

			setCardData(parsedClients);
		});

		return () => {
			// Cleanup the listener when the component is unmounted
			clientsRef.off("value");
		};
	}, [uid]);

	return {cardData, handleDeleteClient };
}
