import { Card } from "react-bootstrap";
import { Button } from "../Button";

import './styles.scss';

type ClientCardProps = {
    client: {
        responsibleName: string,
        responsibleCPF: string,
        name: string,
        cpf: string,
        email: string,
        description: string,
        phone: string,
        value: string,
    };
    valueFirstButton: string,
    onDelete: () => void;
    onUse: () => void;
}

export function ClientCard({client, valueFirstButton, onDelete, onUse}: ClientCardProps) {
    return(
        <Card id="card">
            <Card.Body>
                <Card.Title id="card-title">{client.name}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted" id="card-subtitle">{client.responsibleName}</Card.Subtitle>
                <Card.Text id="card-text">
                    {client.description}
                </Card.Text>
                <div className="actions-card">
                    <Button onClick={onUse}>{valueFirstButton}</Button>
                    <Button isDanger onClick={onDelete}>Deletar</Button>
                </div>
            </Card.Body>
        </Card>
    );
}