class ConvertFileToBase64 {
    state = {
      file: null as File | null,
      base64URL: ""
    };
  
    getBase64 = (file: File): Promise<string> => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        console.log("A");
  
        reader.onload = () => {
          const base64String = reader.result as string;
          
          resolve(base64String);
        };
  
        reader.onerror = (error) => {
          console.error('Error reading the file: ', error);
          reject(new Error('Failed to read file as base64'));
        };

        reader.readAsDataURL(file);
      });
    };
  
    handleFileInputChange = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
      const file = e.target.files?.[0];
  
      if (!file) return;
  
      try {
        const base64URL = await this.getBase64(file);
        
        this.setState({
          base64URL,
          file
        });
      } catch (error) {
        console.error('Error converting file to base64:', error);
      }
    };
  
    setState(newState: { base64URL: string; file: File | null }): void {
      this.state = {
        ...this.state,
        ...newState
      };
    }
}

export default ConvertFileToBase64;