import { useState, useEffect } from 'react';
import { database } from '../services/firebase';

export function useSubscription(userId: string) {
  const [subscriptionStatus, setSubscriptionStatus] = useState<string>('free');
  const [receiptsCount, setReceiptsCount] = useState(0);

  useEffect(() => {
    const subscriptionRef = database.ref(`users/${userId}/subscription`);
    
    subscriptionRef.on('value', (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setSubscriptionStatus(data.status);
        setReceiptsCount(data.receiptsGenerated || 0);
      }
    });

    return () => subscriptionRef.off();
  }, [userId]);

  const canGenerateReceipt = () => {
    return subscriptionStatus === 'premium' || receiptsCount < 10;
  };

  return { subscriptionStatus, receiptsCount, canGenerateReceipt };
}
