import { ButtonHTMLAttributes } from 'react'
import './styles.scss';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  isOutlined?: boolean;
  isDanger?: boolean;
  isInfo?: boolean;
  isSecondary?: boolean;
};

export function Button({ isOutlined = false, isDanger = false, isInfo = false, isSecondary = false, ...props }: ButtonProps) {
  return (
    <button 
      className={`button ${isOutlined ? 'outlined' : ''} ${isDanger ? 'danger' : ''} ${isInfo ? 'info' : ''} ${isSecondary ? 'secondary' : ''}`}
      {...props} />
  );
}