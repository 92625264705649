import React, { useState } from 'react';
import './styles.scss';
import Modal from '../Modal';
import { loadStripe } from '@stripe/stripe-js';
import { toast } from 'react-toastify';
import { NavigateFunction, useNavigate } from 'react-router-dom';

export function UpgradeButton() {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);
  const handleSubscription = async (priceId: string) => {
    try {
      const response = await fetch('/api/create-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          priceId,
          successUrl: `${window.location.origin}/issue-receipt?status=success`,
          cancelUrl: `${window.location.origin}/issue-receipt?status=canceled`,
        }),
      });

      const { sessionId } = await response.json();
      const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!);
      
      const { error } = await stripe?.redirectToCheckout({ sessionId }) || {};
      
      if (error) {
        toast.error('Erro ao processar pagamento');
        navigate('/issue-receipt');
      }
    } catch (error) {
      toast.error('Erro ao processar pagamento');
      navigate('/issue-receipt');
    }
  };

  return (
    <div className="subscription-modal">
      <button className='rainbow-button' onClick={openModal}>Upgrade</button>

      <Modal isOpen={isOpen} onClose={closeModal}>
        <h1>Escolha Seu Plano</h1>
        <div className="plans">
          <PlanCard 
            title="Iniciante"
            price="Grátis"
            priceId='price_0'
            features={["Emissão de recibos limitada", "Acesso básico", "Suporte padrão"]}
            buttonText="Comece grátis"
            onSubscribe={() => handleSubscription('price_0')}
          />
          <PlanCard 
            title="Profissional"
            price="R$29,99/mês"
            priceId="price_1QGwtUC0qfsQ5TqqmqCqtqKo"
            features={["Emissão de recibos ilimitada", "Acesso completo", "Suporte prioritário"]}
            buttonText="Assine Profissional"
            isPopular
            onSubscribe={() => handleSubscription("price_1QGwtUC0qfsQ5TqqmqCqtqKo")}
          />
          <PlanCard 
            title="Empresarial"
            price="R$59,99/mês"
            priceId='price_1QGx02C0qfsQ5Tqq3aAE5bO8'
            features={["Emissão ilimitada e personalizada", "Acesso avançado", "Suporte premium 24/7"]}
            buttonText="Assine Empresarial"
            onSubscribe={() => handleSubscription('price_1QGx02C0qfsQ5Tqq3aAE5bO8')}
          />
        </div>
      </Modal>
    </div>
  );
}

interface PlanCardProps {
  title: string;
  price: string;
  priceId: string;
  features: string[];
  buttonText: string;
  isPopular?: boolean;
  onSubscribe: () => Promise<void>;
}


const handleSubscription = async (priceId: string, navigate: NavigateFunction) => {
  try {
    const baseUrl = process.env.REACT_APP_API_URL || '';
    const response = await fetch(`${baseUrl}/api/create-checkout-session`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        priceId,
        successUrl: `${window.location.origin}/issue-receipt?status=success`,
        cancelUrl: `${window.location.origin}/issue-receipt?status=canceled`,
      }),
    });

    const { sessionId } = await response.json();
    const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!);
    
    if (!stripe) {
      toast.error('Configuração do Stripe não encontrada');
      return;
    }
    
    const { error } = await stripe.redirectToCheckout({ sessionId });
    
    if (error) {
      toast.error('Erro ao processar pagamento');
      navigate('/issue-receipt');
    }
  } catch (error) {
    toast.error('Erro ao processar pagamento');
    navigate('/issue-receipt');
  }
};

function PlanCard({ title, price, priceId, features, buttonText, isPopular} : PlanCardProps) {
  const navigate = useNavigate();

  return (
    <div className={`plan-card ${isPopular ? 'popular' : ''}`}>
      {isPopular && <div className="badge">Mais Popular</div>}
      <h2>{title}</h2>
      <p className="price">{price}</p>
      <ul className="features">
        {features.map((feature: string, index: number) => (
          <li key={index}>{feature}</li>
        ))}
      </ul>
      <button className="subscribe-button" onClick={() => handleSubscription(priceId, navigate)}>{buttonText}</button>
    </div>
  );
}
